// Colors
$gray-lighter: #f3f3f3;
$gray-light: #b9b9b9;
$gray: #838383;
$gray-dark: #404040;
$gray-darker: #111827;

.icon {
    &-sm {
        fill: currentColor;
        width: 16px;
        height: 16px;
    }
    &-md {
        fill: currentColor;
        width: 24px;
        height: 24px;
    }
    &-lg {
        fill: currentColor;
        width: 32px;
        height: 32px;
    }
    &-xlg {
        fill: currentColor;
        width: 40px;
        height: 40px;
    }
}

.form-control:focus, .is-invalid {
    border-color: #dc3545 !important;
    box-shadow: 0 0 0 0.2rem rgb(255 0 0 / 40%) !important;
}

// Utils
.bg-gray-lighter {
    background-color: $gray-lighter !important;
}
.text-gray-lighter {
    color: $gray-lighter !important;
}
.bg-gray-light {
    background-color: $gray-light !important;
}
.text-gray-light {
    color: $gray-light !important;
}
.bg-gray {
    background-color: $gray !important;
}
.text-gray {
    color: $gray !important;
}
.bg-gray-dark {
    background-color: $gray-dark !important;
}
.text-gray-dark {
    color: $gray-dark !important;
}
.bg-gray-darker {
    background-color: $gray-darker !important;
}
.text-gray-darker {
    color: $gray-darker !important;
}
.rounded-md {
    border-radius: 0.50rem !important;
}
.rounded-xlg {
    border-radius: 1rem !important;
}
.leading-tight {
    line-height: 1.25;
}
.leading-normal {
    line-height: 1.5;
}
.height-screen {
    height: 100vh;
}
.cursor-pointer {
    cursor: pointer;
}
.cursor-not-allowed {
    cursor: not-allowed;
}
.relative {
    position: relative;
}
.border-2-light-gray {
    border: 4px solid $gray-light !important;
}
.bg-gray-transparent {
    background-color: rgb(0 0 0 / 40%);
}
.hover-border-danger:hover {
    border-color: #dc3545 !important;
}
.loading-modal {
    .modal-dialog {
        .modal-content {
            position: fixed;
            top: 50%;
            left: 50%;
            background-color: transparent;
            box-shadow: none;
            border: none;
        }
    }
    .modal-backdrop {
        background-color: rgba(0,0,0,.0001) !important;
    }
}
.form-control-xlg {
    height: calc(2em + 1.3rem + 4px) !important;
    padding: .8rem 1.2rem !important;
    font-size: 1.35rem !important;
    line-height: 1.7 !important;
    border-radius: .4rem !important;
}
.btn-xlg {
    padding: .6rem 1.5rem !important;
    font-size: 1.5rem !important;
    line-height: 1.7 !important;
    border-radius: .5rem !important;
}
.bg-danger-gradient {
    background: rgb(220,53,69);
    background: -moz-linear-gradient(180deg, rgba(220,53,69,1) 13%, rgba(152,30,30,1) 99%);
    background: -webkit-linear-gradient(180deg, rgba(220,53,69,1) 13%, rgba(152,30,30,1) 99%);
    background: linear-gradient(180deg, rgba(220,53,69,1) 13%, rgba(152,30,30,1) 99%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#dc3545",endColorstr="#981e1e",GradientType=1);
}
.v-align-middle {
    vertical-align: middle !important;
}
// .shadow-sm {
//     -webkit-box-shadow: 0px 0px 16px 5px rgba(0,0,0,0.43);
//     -moz-box-shadow: 0px 0px 16px 5px rgba(0,0,0,0.43);
//     box-shadow: 0px 0px 16px 5px rgba(0,0,0,0.43);
// }
// /End of Utils