.time {
    font-size: 4.5rem;
}
.period {
    font-size: 2.75rem;
}
.date {
    font-size: 1.5em;
}
@media screen and (min-width: 992px) {
    .time {
        font-size: 6rem;
    }
    .period {
        font-size: 4rem;
    }
    .date {
        font-size: 1.75em;
    }
}
@media screen and (min-width: 1200px) {
    .time {
        font-size: 7rem;
    }
    .period {
        font-size: 4rem;
    }
    .date {
        font-size: 2em;
    }
}
@media screen and (min-width: 1400px) {
    .time {
        font-size: 8rem;
    }
    .period {
        font-size: 4rem;
    }
}